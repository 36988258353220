.emptyCompetitorCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--space-base);
  padding: var(--space-base) var(--space-larger);
  border-radius: var(--radius-base);
  text-align: center;
  background-color: var(--color-surface--background);
}
