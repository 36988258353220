.container {
  display: flex;
  flex-direction: column-reverse;
  margin: var(--space-small);
  margin-bottom: 0;

  @media (--large-screens-and-up) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-extravagant);
    margin: var(--space-base) var(--space-extravagant);
    align-items: center;
  }
}

.contentSection {
  > * {
    margin-bottom: var(--space-base);
  }

  padding-top: 0;

  > h2 {
    margin-bottom: var(--space-large);
  }

  @media (--large-screens-and-up) {
    max-width: 304px;
    padding-top: 0;
  }
}

.featureLabel {
  margin-top: var(--space-base);
  margin-bottom: var(--space-base);
}

.labelContainer,
.placeholderLabel {
  margin-top: var(--space-base);
  margin-bottom: var(--space-base);
}

.placeholderLabel {
  height: 24px;
}

@media (--large-screens-and-up) {
  .placeholderLabel {
    visibility: hidden;
  }
}

.content p {
  margin-bottom: var(--space-base);
}

.imageSection {
  display: flex;
  margin-bottom: var(--space-small);
  justify-content: center;
  align-items: center;

  img {
    max-height: 270px;
  }
}
