.cardStyling {
  display: grid;
  max-width: 100%;
  margin-bottom: 0 !important;
  overflow: hidden;
  grid-column-gap: var(--space-base);
}

.flexCardStyling {
  display: flex;
}

.flexColumnCardStyling {
  display: flex;
  flex-direction: column;
}

.fullHeight {
  height: 100%;
}

.oneColumn {
  grid-template-columns: minmax(0, 1fr);
}
.twoColumns {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.threeColumns {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.fourColumns {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.emptyStateCard_container {
  height: 100%;
  padding-bottom: 1rem;
}
