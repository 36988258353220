.container {
  button {
    justify-content: space-between;
    padding: 0;
    text-align: left;
    span {
      flex: 0 0 85%;
      font-weight: normal;
    }
    svg {
      fill: var(--color-interactive) !important;
      border-radius: var(--radius-base);
    }
    &:hover {
      box-shadow: unset;
      background-color: transparent !important;
      svg {
        background-color: var(--color-surface--hover);
      }
    }
    &:active svg {
      box-shadow: var(--shadow-focus);
    }
  }
}
