.imageContainer {
  display: flex;
  align-items: center;

  @media (--medium-screens-and-up) {
    flex: 1 1 100%;
    min-height: 395px;
  }
}

.imageContainer > img {
  object-fit: cover;
}

.styledDividerContainer {
  display: flex;
  flex-direction: row;
  gap: var(--space-small);
  align-items: center;
}

.dividerContainer {
  flex-grow: 1;
}

.gpsTrackingModalLayout {
  display: flex;
  flex-flow: row wrap-reverse;
  gap: var(--space-large);
  padding: 0 var(--space-base) var(--space-larger);

  @media (--medium-screens-and-up) {
    flex-flow: row nowrap;
    gap: var(--space-extravagant);
    padding: 0 var(--space-extravagant) var(--space-extravagant);
  }
}

.gpsTrackingModalContentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-larger);
  flex: 1 1 100%;
  justify-content: center;
}
