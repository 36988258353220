.cardContainer {
  display: flex;
  height: 150px;
  min-width: 300px;
}

.cardContent {
  display: flex;
  height: 100%;
  max-height: 150px;
  padding: var(--space-base);
  flex-direction: column;
  justify-content: space-between;
}

.childrenContainer {
  height: 80px;
}

.metricsContainer {
  display: flex;
  gap: var(--space-larger);
}
