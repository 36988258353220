.carouselContainer {
  /* Add extra bottom padding on small screens to prevent dots overlapping with modal buttons */
  padding-bottom: var(--space-larger);
}

@media (--medium-screens-and-up) {
  .carouselContainer {
    padding-bottom: 0;
  }

  :global([data-testid="modal-header"]) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
