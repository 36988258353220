.cardsContainer {
  width: 100%;
}

.desktopCards {
  display: none;
}

.mobileCards {
  flex: 1 1 auto;
  height: 100%;
}

.mobileCards > div {
  height: 100%;
}

.mediaContainerCompact {
  width: 100%;
  aspect-ratio: 16/9;
  max-width: 500px;
  max-height: 100%;
  min-height: 213px;
}

.mediaContainerExpanded {
  display: flex;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  max-width: 300px;
  max-height: 200px;
}

@media not all and (--large-screens-and-up) {
  .cardWrapper {
    height: 100%;
  }
}

.cardWrapper > div {
  height: 100%;
}

.cardWrapper > div > div {
  height: 100%;
}

.cardWrapper > div > div > div {
  height: 100%;
}

@media (--large-screens-and-up) {
  .desktopCards {
    display: flex;
    flex-direction: row;
    gap: var(--space-base);
    align-content: stretch;
    align-items: stretch;
  }

  .desktopCards > * {
    flex: 1 1 0px;
  }

  .mobileCards {
    display: none;
  }

  .cardWrapper {
    flex: 1 1 0px;
  }
}
