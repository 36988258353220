.container {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.container[data-expanded="true"] {
  overflow-x: visible;
  overflow-y: visible;
}

.menu {
  --menu-item--height: 3.75rem;
  display: none;
  position: absolute;
  z-index: var(--elevation-menu);
  box-shadow: var(--shadow-base);
  padding: var(--space-smaller);
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
  text-align: center;
  background: var(--color-surface);
  opacity: 0;
  transform: translateY(-0.625rem);
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  flex-direction: column;
  gap: var(--space-smaller);
  transform-origin: 0;
}

.button {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 3rem;
  padding: var(--space-small) var(--space-base);
  color: var(--color-heading);
  font-family: var(--typography--fontFamily-normal);
  font-size: var(--typography--fontSize-base);
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  letter-spacing: inherit;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  transition: background-color var(--timing-base) linear;
  flex-flow: row;
  align-items: center;
}

.button:hover,
.button:focus,
.button[aria-expanded="true"] {
  background-color: var(--color-surface--background--hover);
}

.icon {
  display: block;
  width: auto;
  margin: 0 var(--space-base) 0 0;
  text-align: center;
  transition: transform var(--timing-quick) ease-in-out;
  flex-shrink: 0;
}

.button[aria-expanded="true"] .icon {
  transform: rotate(45deg);
}

@media screen and (min-width: 40rem) {
  .menu {
    flex-direction: row;
  }

  .menu a {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}

/* 1. Magic number to prevent the arrow from overlapping the hover state of the first menu item */
/* 2. Keeps arrow position in sync with padding, arrow size, and menu item size */

.menu::before {
  --pointer-size: 0.5rem;
  --pointer-offset: calc(-1 * calc(var(--pointer-size) / 2));
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: var(--pointer-offset);
  width: var(--pointer-size);
  height: var(--pointer-size);
  /* 1 */
  border-top-right-radius: 6px;
  background-color: inherit;
  /* 2 */
  transform: translateY(
      calc(
        var(--space-smaller) + calc(var(--menu-item--height) / 2) +
          var(--pointer-offset)
      )
    )
    rotate(45deg);
}

.menu a {
  display: flex;
  width: 4.5rem;
  height: var(--menu-item--height);
  padding: 0;
  border-radius: var(--radius-small);
  color: var(--color-text);
  font-weight: 600;
  background: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu a:hover {
  background-color: var(--color-surface--hover);
}

.menu:not(:hover) a:focus {
  background-color: var(--color-surface--hover);
}

:global(.is-collapsed) .button > span:not(.icon) {
  display: none;
}

.menu[data-toggled="true"] {
  display: flex;
  animation-name: animation-quickCreate;
}

@keyframes animation-quickCreate {
  from {
    opacity: 0;
    transform: translateY(-0.625rem) scale(0.8);
  }

  to {
    opacity: 1;
    transform: translateY(-0.625rem) scale(1);
  }
}
