.imageContainer {
  max-width: 225px;
  margin: 0 auto;
}
.previewSection {
  display: grid;
  margin-top: var(--space-small);
  gap: var(--space-small);
}
.previewSectionCentred {
  text-align: center;
}
