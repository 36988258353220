.recipientContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.summaryMessage {
  flex: 1;
}
