.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-base);
}

.itemWrapper {
  display: flex;
  flex: 1;
  align-items: center;
}

.itemColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--space-base);
}

.itemIcon {
  display: flex;
  align-items: center;
  margin-left: var(--space-base);
}

.ratingAndReviews {
  display: flex;
  align-items: center;
  gap: var(--space-base);
}

.ratingGroup, .reviewCountGroup {
  display: flex;
  align-items: center;
  gap: var(--space-small);
}

.inlineLabel {
  display: inline-block;
  width: fit-content;
} 

.ranking {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-smallest);
  width: 60px;
  height: 60px;
  margin-right: var(--space-large);
  border-radius: var(--radius-base);
  background-color: var(--color-surface--hover);
  flex-shrink: 0;
}

.reviewAverageChange {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-smallest);
  padding: var(--space-smallest) var(--space-small) var(--space-smallest) var(--space-smallest);
  border-radius: var(--radius-small);
}


.positive b {
  color: var(--color-success);
}

.negative b {
  color: var(--color-critical);
}

.neutral b {
  color: var(--color-greyBlue);
}

.reviewAverageChange.positive, .reviewAverageChange.positive * {
  color: var(--color-success);
  background-color: var(--color-job--surface);
}

.reviewAverageChange.negative, .reviewAverageChange.negative * {
  color: var(--color-critical);
  background-color: var(--color-critical--surface);
}
