.benchmarkContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-large);
  }

  .benchmarkHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.loading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: 100vh;
}
.item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--space-small) var(--space-base) var(--space-small) var(--space-smallest);
}
.itemColumn {
     display: flex;
     flex-direction: column;
     gap: var(--space-base);
     padding: var(--space-base);
 }
.itemIcon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
}
.itemContent {
    display: flex;
    flex-shrink: 1;
    justify-content: space-between;
    gap: var(--space-base);
}
.ratingGroup {
  display: flex;
  align-items: center;
  gap: var(--space-small);
}
.inlineLabel {
  display: inline-block;
  width: fit-content;
}
