.container {
  display: flex;
  flex-direction: column;
  max-height: 252px;
  padding: var(--space-large);
  gap: var(--space-small);
  align-items: center;
  --public-tab--inset: 0;
}

.column1 {
  order: 2;
  max-width: 30rem;
  margin: auto 0;
}

.column2 {
  order: 1;
}

.buttonRow {
  display: flex;
  margin-top: var(--space-large);
  flex-direction: row;
  gap: var(--space-small);
}

.subtitle {
  margin-top: var(--space-large);
}

.child1 {
  margin-top: var(--space-large);
}

@media (--medium-screens-and-up) {
  .container {
    width: 950px;
  }
}

@media (--large-screens-and-up) {
  .container {
    flex-direction: row;
    gap: var(--space-extravagant);
  }

  .column1 {
    order: 1;
  }
  
  .column2 {
    order: 2;
  }
}
