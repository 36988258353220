.landingPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerContainer {
  max-width: 954px;
  padding: var(--space-largest) var(--space-base) 120px var(--space-base);
  text-align: center;
}

.headerButtonContainer {
  margin-bottom: var(--space-largest);
}

.tapeHeaderImg {
  width: 203.8px;
  margin: 0 auto;
}

.highlightsContainer {
  display: flex;
  flex-direction: column;
  max-width: 954px;
  padding: 0 var(--space-base);
  gap: var(--space-largest);

  @media (--large-screens-and-up) {
    gap: 120px;
  }
}

.comingSoonContainer {
  width: 100%;
  margin-top: var(--space-largest);
  padding: var(--space-larger) var(--space-base);
  text-align: center;
  background-color: var(--color-surface--background);

  @media (--large-screens-and-up) {
    margin-top: 120px;
  }
}

.content {
  max-width: 589px;
  margin: 0 auto;
}

.content h2 {
  margin: var(--space-small) 0 var(--space-base) 0;
}

.footerContainer {
  width: 100%;
  padding: calc(var(--space-largest) * 2) var(--space-large);
  text-align: center;
  background: var(--color-surface--reverse);
  background-image: url("~@images/backgroundGrain.png");
}

.footerBody {
  color: var(--color-text--reverse--secondary);
  font-size: var(--typography--fontSize-larger);
  font-weight: 800;
  line-height: 24px;
}

.footerTitle {
  max-width: 460px;
  margin: 0 auto var(--space-base) auto;
  color: var(--color-text--reverse);
  font-size: var(--typography--fontSize-jumbo);
  font-weight: 900;
  line-height: 39.96px;
}

.footerPrice {
  margin-left: var(--space-small);
}

.footerBasePrice {
  margin-right: var(--space-small);
  color: var(--color-text--reverse--secondary);
  text-decoration: line-through;
}


/* EXPERIMENTAL */
.experimentalLandingPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 950px;
  margin: 0 auto;
  padding: 0 var(--space-base);
}

.experimentalLandingPageContainer .headerContainer {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: var(--space-base) var(--space-smallest);
  background-color: var(--color-surface);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--space-base);

  @media (--large-screens-and-up) {
    position: sticky;
    padding: var(--space-large);
    flex-direction: row;
    justify-content: space-between;
  }
}

.experimentalLandingPageContainer .headerContainer .tapeHeaderImg {
  width: 203.8px;
  margin: 0;
}

.experimentalLandingPageContainer .heroSection {
  padding: var(--space-small);
  @media (--large-screens-and-up) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: var(--space-large);
    padding: var(--space-largest);
    background-color: var(--color-surface--background--subtle);
  }
}

.experimentalLandingPageContainer .heroImgContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 var(--space-largest);
  border-radius: var(--radius-large);
  background-color: var(--color-border);
  gap: var(--space-largest);
}

.experimentalLandingPageContainer .heroImgContainer .heroImgContent h1 {
  margin-bottom: var(--space-base);
}

.experimentalLandingPageContainer .heroImgContainer .heroImgContent p {
  text-align: start;
  text-justify: inter-word;
}

.experimentalLandingPageContainer .heroSectionContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--space-larger);
  gap: var(--space-largest);
}

.experimentalLandingPageContainer .heroSectionContentItem {
  min-height: 50px;
  @media (--large-screens-and-up) {
    display: flex;
    min-height: 175px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-base);

  }
}

.experimentalLandingPageContainer .suiteSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: var(--space-large);
  padding: var(--space-large);
  gap: var(--space-large);
}

.experimentalLandingPageContainer .videoSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: var(--space-large);
  gap: var(--space-base);
}

.experimentalLandingPageContainer .videoSectionContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--space-small);
  flex-grow: 1;
  flex-basis: 0;
}

.experimentalLandingPageContainer .videoSectionContent iframe {
  width: 100%;
  border-radius: var(--radius-large);
  overflow: hidden;
  aspect-ratio: 16/9;
}

.experimentalLandingPageContainer .testimonialSection {
  margin-top: calc(var(--space-largest) * 2);
  border-radius: var(--radius-large);
  background-color: var(--color-surface--hover);
  @media(--large-screens-and-up) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.experimentalLandingPageContainer .testimonialSectionContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--space-large);
  @media (--large-screens-and-up) {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
    justify-content: center;
    padding: 0 var(--space-largest) 0;
  }
}

.experimentalLandingPageContainer .testimonialSectionContentItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: var(--space-smallest);
  gap: var(--space-small);
}

.experimentalLandingPageContainer .testimonialSectionVideo {
  width: 100%;
  border-radius: 0 0 var(--radius-large) var(--radius-large);
  overflow: hidden;
  aspect-ratio: 16/9;
  @media (--large-screens-and-up) {
    width: 50%;
    height: 430px;
    border-radius: var(--radius-large);
  }
}

.experimentalLandingPageContainer .featuresSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: var(--space-largest);
  margin-top: calc(var(--space-large) * 2);
}

.experimentalLandingPageContainer .featuresSectionContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: var(--space-large);
}

.experimentalLandingPageContainer .featuresSectionContentItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 0 var(--space-large) 0 0;
  gap: var(--space-large);
}

.experimentalLandingPageContainer .featuresSectionContentItemImg {
  width: 50%;
}

.experimentalLandingPageContainer .footerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: calc(var(--space-large) * 2);
  padding: var(--space-larger);
  background-image: url("@images/experimental_background.png");
  gap: var(--space-large);
  @media (--large-screens-and-up) {
    padding: calc(var(--space-larger) * 2);
  }
}

@media (max-width: 950px) {
  .experimentalLandingPageContainer .heroImgContainer,
  .experimentalLandingPageContainer .heroSectionContent,
  .experimentalLandingPageContainer .suiteSection,
  .experimentalLandingPageContainer .headerContainer,
  .experimentalLandingPageContainer .videoSection,
  .experimentalLandingPageContainer .featuresSection,
  .experimentalLandingPageContainer .testimonialSection,
  .experimentalLandingPageContainer .featuresSectionContent {
    flex-direction: column;
  }

  .experimentalLandingPageContainer .featuresSection h1 {
    text-align: center;
  }

  .experimentalLandingPageContainer .testimonialSection .testimonialSectionContent .testimonialSectionVideo {
    width: 100%;
  }

  .experimentalLandingPageContainer .heroImgContainer,
  .experimentalLandingPageContainer .featuresSectionContentItem,
  .experimentalLandingPageContainer .featuresSectionContentItemImg,
  .experimentalLandingPageContainer .testimonialSectionContent {
    width: 100%;
  }

  .experimentalLandingPageContainer .heroImgContainer {
    padding: var(--space-large);
  }
}
