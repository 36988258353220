.richTextContainer :global(.ql-toolbar.ql-snow) {
  border: var(--border-base) solid var(--field--border-color);
  border-bottom: var(--border-base) solid var(--color-border);
  border-top-right-radius: var(--public-field--top-right-radius);
  border-top-left-radius: var(--public-field--top-left-radius);
}

.richTextContainer :global(.ql-container.ql-snow) {
  height: auto;
  border: var(--border-base) solid var(--field--border-color);
  border-bottom-right-radius: var(--public-field--bottom-right-radius);
  border-bottom-left-radius: var(--public-field--bottom-left-radius);
  color: var(--color-text);
  --typography--fontFamily-normal: Helvetica, Arial, "Lucida Grande", sans-serif;
  --typography--fontFamily-display: Helvetica, Arial, "Lucida Grande",
    sans-serif;
  --typography--fontSize-base: var(--typography--fontSize-large);
  --typography--lineHeight-base: var(--typography--lineHeight-large);
  font-feature-settings: "clig" off, "liga" off;
}

.richTextContainer :global(.ql-editor, .ql-toolbar.ql-snow) {
  /* stylelint-disable-next-line declaration-property-value-allowed-list */
  font-family: Helvetica, sans-serif;
}

.richTextContainer
  :global(.ql-snow .ql-picker.ql-font span[data-value="Helvetica"]::before) {
  content: "Helvetica";
  /* stylelint-disable-next-line declaration-property-value-allowed-list */
  font-family: "Helvetica", sans-serif;
}

.richTextContainer
  :global(.ql-snow .ql-picker.ql-font span[data-value="Georgia"]::before) {
  content: "Georgia";
  /* stylelint-disable-next-line declaration-property-value-allowed-list */
  font-family: "Georgia", serif;
}

.richTextContainer
  :global(.ql-snow .ql-picker.ql-font span[data-value="Courier New"]::before) {
  content: "Courier New";
  /* stylelint-disable-next-line declaration-property-value-allowed-list */
  font-family: "Courier New", serif;
}

.richTextContainer
  :global(.ql-snow .ql-picker.ql-font span[data-value="Trebuchet MS"]::before) {
  content: "Trebuchet MS";
  /* stylelint-disable-next-line declaration-property-value-allowed-list */
  font-family: "Trebuchet MS", serif;
}

.richTextContainer
  :global(.ql-snow .ql-picker.ql-font span[data-value="Arial"]::before) {
  content: "Arial";
  /* stylelint-disable-next-line declaration-property-value-allowed-list */
  font-family: "Arial", serif;
}

.richTextContainer :global(.ql-container.ql-snow) p,
.richTextContainer :global(.ql-container.ql-snow) h1,
.richTextContainer :global(.ql-container.ql-snow) h2 {
  margin-bottom: var(--space-base);
}

.richTextContainer :global(.ql-container.ql-snow) p:last-child,
.richTextContainer :global(.ql-container.ql-snow) h1:last-child,
.richTextContainer :global(.ql-container.ql-snow) h2:last-child {
  margin-bottom: 0px;
}

.richTextContainer [data-testid="Form-Field-Wrapper"] input[name="body"] {
  /** hide the input  but allow the Atlantis form to fire validations*/
  position: absolute;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
}

.richTextContainer [data-testid="Form-Field-Wrapper"] {
  --field--height: auto;
  border: none;
}

.richTextContainer [data-testid="Form-Field-Wrapper"]:focus-within,
.richTextContainer [data-testid="Form-Field-Wrapper"]:focus,
.richTextContainer [data-testid="Form-Field-Wrapper"]:active {
  box-shadow: var(--shadow-focus);
  border-top-right-radius: var(--public-field--top-right-radius);
  border-bottom-right-radius: var(--public-field--bottom-right-radius);
  border-bottom-left-radius: var(--public-field--bottom-left-radius);
  border-top-left-radius: var(--public-field--top-left-radius);
  outline: none;
}

.richTextContainer {
  position: relative;

  img {
    display: inline;
  }
}

.loading {
  display: none;
}

.rephrasingGlimmer {
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
  position: absolute;
  top: 42px;
  right: 1px;
  left: 1px;
  height: calc(100% - 43px);
  padding: 15px;
  background: var(--color-surface);
}

.rephrasingGlimmer > div:last-child {
  width: 80%;
  flex: 1;
}

.imageResizeMenu {
  display: none;
  position: absolute;
  z-index: 1;
}

.imageResizeMenuButtons {
  display: flex;
  gap: var(--space-small);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: var(--space-small);
  border-radius: var(--space-smallest);
  background: var(--color-surface);
}

.richTextContainer :global(.ql-editor) {
  word-break: break-word;
}
