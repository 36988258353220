.cardHeader {
  width: fit-content;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: var(--color-base-blue--500);
  text-decoration-thickness: 2px;
  text-decoration-style: dotted;
  text-underline-offset: 4px;
  cursor: default;
}

.flexRowContainer {
  display: flex;
  gap: 20px;
}
