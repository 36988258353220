.imageContainer {
  width: 350px;
  margin: 0 auto;
}

.stepContainer {
  padding: 0 var(--space-large);

  @media (--large-screens-and-up) {
    width: 100%;
    max-width: 850px;
  }
}
