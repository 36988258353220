.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  aspect-ratio: 1;
  border-radius: 50%;
}

.checked {
  border: 1px solid var(--color-interactive);
  background-color: var(--color-interactive);
}

.unchecked {
  border: 1px solid var(--color-greyBlue);
  background-color: transparent;
} 

.progressBar {
  display: flex;
  align-items: center;
  gap: var(--space-small);
  white-space: nowrap;
}

.disclosureContainer {
  min-height: 230px;
}
