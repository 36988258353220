.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--space-base);
}

.KPICardRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-smaller);
}

.heading {
  /* We want to override native spacing on H3 */
  margin-bottom: var(--space-small) !important;
}

.glimmerRow {
  margin-bottom: var(--space-smaller);
}

.bannersContainer {
  display: grid;
  padding: var(--space-small) var(--space-base);
  gap: var(--space-base);
}

.pageWrapper {
  display: flex;
  justify-content: center;
}

.overviewContainer {
  display: flex;
  gap: var(--space-small);
}
